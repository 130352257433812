@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

@mixin custom-box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#webpack-dev-server-client-overlay {
  display: none;
}

$dark-grey: #888C8C;
$main-color: #206AF1;
$light-blue: #E4F0FF;
$main-red: #d90000;
$orange: #ff8c00;
$defaultPaymentBg: #98fb988a;
$large-font-size: 32px;
$medium-font-size: 18px;
$small-font-size: 15px;
$smaller-font-size: 12px;

body {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;

}

.ant-image-img {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.header {
  display: table;
  margin:  auto;

}

img {
  pointer-events: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.search-input {
  margin-top: 15px;
  margin-bottom: 15px;

}

.ant-badge-count {
  top: 15px !important;
  right: 15px !important;
  transform: none !important;
}

.title {
  display: none;

  font-family: 'Nunito', Arial, Helvetica, sans-serif;

  color: #206af1 !important;

  font-size: 36px !important;

  font-weight:  bold !important;

  text-align: center;

}

.depositValue {
  text-align: right;

}

td.image-row {
  max-width: 150px !important;

  height:  auto;

  padding:  0 !important;

  line-height: 0;

  vertical-align: middle;

  text-align: center;

}

td.image-row {
  border-right: none !important;

}

.brandName, .productName {
  overflow-wrap: unset !important;

}

.ant-float-btn-circle {
  width: auto !important;
}

thead .image-row {
  padding: 16px 16px !important;

}

thead .productName {
  display: none;

}

.ant-image-preview-operations-operation:nth-child(4),
.ant-image-preview-operations-operation:nth-child(5) {
  display: none !important;

}

.ant-input-affix-wrapper {
  border-radius: 20px !important;

}

.ant-table.ant-table-bordered > .ant-table-container,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border: none !important;

}

.ant-table-tbody {
  display: flex;
  flex-wrap: wrap;

}

.ant-table-empty {
  justify-content: center;
  display: flex;

}

.ant-table-row {
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  margin: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.ant-table-row:hover td,
.ant-table-row:active td,
.ant-table-row:focus td,
.ant-table-row:active:focus td,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: white !important;

}

.ant-table-cell {
  border: none !important;

}

.ant-table-cell .brandWrapper {
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  min-height: 40px;

}

.ant-table-pagination.ant-pagination {
  margin-right: 15px !important;

}

.avatar {
  width: 125px;
  height: 65px;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  z-index: 1;
}
.brandWrapper .ant-tag {
  max-width: 80px;
  text-wrap: wrap;
  font-weight: bold;
  border-radius: 6px !important;

}
.brandWrapper .ant-image {
  position: absolute;
  right: 0;
  max-width: 120px;
}

.brandName {
  border-radius: 20px !important;
  padding-bottom: 15px !important;
}

.ant-table-cell.brandName img {
  max-width: 112px;
  max-height: 70px;

}

.productName {
  margin-bottom: auto;
  font-weight: bold;

}

.depositWrapper {
  display: flex;
  justify-content: flex-end;
}

.depositValue {
  border-radius: 20px;

}

.ant-table-cell.image-row {
  min-width: 100%;
  padding: 0 20px !important;
  align-self: center;

}

.ant-tag-has-color {
  border-radius: 6px;

}

.spinner-container {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  
}

.ant-table-wrapper {
  padding-bottom: 50px;

}

.ant-back-top {
  bottom: 15px !important;

}

.ant-pagination-item, .ant-select .ant-select-selector {
  font-size: 14px;
  min-height: 40px;

}
.product-list {
  .product {
    background-color: white;
    @include custom-box-shadow;
    min-height: 300px;
    padding: 20px;
    border-radius: 20px;
    width: calc(25% - 30px);
    margin: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: inherit;

    @media (max-width: 992px) {
      width: calc(50% - 30px);
      margin: 15px;
    }
    @media (max-width: 576px) {
      width: calc(100% - 30px);
      margin: 15px;
    }

    .avatar {
      width: 65px;
      height: 65px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
    }
    img {
      width: 100%;
      z-index: 0;
      position: relative;
    }
    .badge {
      max-width: 200px;
      text-wrap: wrap;
    }
    .ant-image-mask {
      background: rgb(0 0 0 / 10%) !important;
    }
  }
  .productCardFooter {
    flex: inherit;
    min-height: 100px;
    .brandName {
      display: block;
    }
  }

  .filter-wrapper {
    .search-bar {
      border-color: $dark-grey !important;
      width: auto;
      border-radius: 30px;
      height: 40px;
      width: 250px;

     &::placeholder {
        color: $dark-grey;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: $dark-grey;
      }
    }
  
    .ant-select-selector {
      padding-left: 10px;
      border-radius: 30px;
      border-color: $dark-grey !important;
    }

    .ant-select-selection-placeholder, .ant-select-arrow {
      color: $dark-grey;
    }
  
    .ant-select-selection-item {
      background: $main-color !important;
      color: white;
      .anticon-close {
        color: white;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      .search-filter {
        margin-bottom: 15px;
        width: 100%;
        .search-bar {
          width: 100% ;
        }
      }
      .product-filters {
        flex-direction: column;
        width: 100%;
        .ant-select {
          width: 100%;
          margin-bottom: 15px;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}


.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

@media only screen and (max-width: 338px) {
 .depositValue {
    padding:  16px 8px !important;

  }

}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: flex !important;

  }

}

@media only screen and (max-width: 1024px) {
  .ant-table-row {
    width: calc(33% - 30px);

  }
 
 }

 @media only screen and (max-width: 768px) {
  .ant-table-row {
    width: calc(50% - 30px);

  }
 }

@media only screen and (max-width: 600px) {
 .image-row {
    width: 75px;

  }

  .productName {
    border-right: none !important;

  }

}

@media only screen and (max-width: 440px) {
  .ant-table-row {
   width: calc(100% - 30px);

  }
 }

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1100px;
    margin: 20px auto;

  }

  .title {
    display: inline-block;

  }

}
